import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getLinkVkyc = (id,data) => {
  return axios.get(`${API_URL}/get-link-vkyc/${id}`, data);
};
export const createLead = (data) => {
  return axios.post(`${API_URL}/create-lead`, data);
};
export const updateLead = (id, data) => {
  return axios.post(`${API_URL}/update-lead/${id}`, data);
};
export const checkPhoneExists = (data) => {
  return axios.post(`${API_URL}/check-exist-phone`, data);
};
export const assignOtp = (data) => {
  return axios.post(`${API_URL}/otp/assign`, data);
};
export const verifyOtp = (data) => {
  return axios.post(`${API_URL}/otp/verify-otp`, data);
};
export const urlDecode = (data) => {
  return axios.post(`${API_URL}/url-decode`, data);
};
export const getRelationships = (data) => {
  return axios.get(`${API_URL}/get-relationship`, data);
};
export const getEmployeeStatus = (data) => {
  return axios.get(`${API_URL}/get-employee-status`, data);
};
export const getMaritalStatus = (data) => {
  return axios.get(`${API_URL}/get-marital-status`, data);
};
export const getPlaceIssues = (data) => {
  return axios.get(`${API_URL}/get-placeIssues`, data);
};
export const getLead = (id) => {
  return axios.get(`${API_URL}/creditcards/${id}/get-creditcard`);
};
export const getCompany = () => {
  return axios.get(`${API_URL}/get-company`);
};
export const getJobPosition = () => {
  return axios.get(`${API_URL}/get-job-position`);
};
export const getGender = () => {
  return axios.get(`${API_URL}/get-gender`);
};
export const getCreateToLOS = (id) => {
  return axios.post(`${API_URL}/creditcards/${id}/create-to-los-api`);
};
export const CheckPhone = (data) => {
  return axios.post(`${API_URL}/check-exist-phone-crm`,data);
};
export const DeletePhone = (data) => {
  return axios.post(`${API_URL}/delete-by-phone`,data);
};
export const getUpdateToLOS = (id) => {
  return axios.put(`${API_URL}/creditcards/${id}/update-los-data-api`);
};
export const uploadOCRFront = (id) => {
  return axios.post(`${API_URL}/creditcards/${id}/upload-orc-front`);
};
export const uploadOCRBack = (id) => {
  return axios.post(`${API_URL}/creditcards/${id}/upload-orc-back`);
};
export const uploadOCRQR = (id) => {
  return axios.post(`${API_URL}/creditcards/${id}/upload-orc-qr`);
};
export const uploadLiveness = (id) => {
  return axios.post(`${API_URL}/creditcards/${id}/upload-orc-liveness`);
};
export const UploadDcument = (id, data) => {
  return axios.post(`${API_URL}/creditcards/${id}/upload-document`, data);
};
export const Starting_info = (data) => {
  return axios.post(`${API_URL}/create-starting-info`, data);
};
export const Basic_info = (id, data) => {
  return axios.post(`${API_URL}/create-basic-info/${id}`, data);
};
export const Residence_info = (id, data) => {
  return axios.post(`${API_URL}/create-residence-info/${id}`, data);
};
export const Job_info = (id, data) => {
  return axios.post(`${API_URL}/create-job-info/${id}`, data);
};
export const Reference_info = (id, data) => {
  return axios.post(`${API_URL}/create-reference_info/${id}`, data);
};
export const uploadEKYCFront = (id, data) => {
  return axios.post(`${API_URL}/credit-card/${id}/ekyc-front`, data);
};
export const uploadEKYCBack = (id, data) => {
  return axios.post(`${API_URL}/credit-card/${id}/ekyc-back`, data);
};
export const uploadEKYCQr = (id, data) => {
  return axios.post(`${API_URL}/credit-card/${id}/ekyc-qr`, data);
};
export const uploadEKYCLiveness = (id, data) => {
  return axios.post(`${API_URL}/credit-card/${id}/ekyc-liveness`, data);
};
export const getDataPhone = (phone_number) => {
  return axios.get(`${API_URL}/credit-card-by-phone/${phone_number}`);
};
export const getFileEcontract = (id) => {
  return axios.get(`${API_URL}/creditcards/${id}/get-file-econtract`);
};
export const confirmEcontract = (id) => {
  return axios.post(`${API_URL}/creditcards/${id}/confirm-econtract`);
};
export const chooseProduct = (id, data) => {
  return axios.post(`${API_URL}/choose-product/${id}`,data);
};
export const getOfferCard = () => {
  return axios.get(`${API_URL}/creditcards/get-offer_cards`);
};
export const getSetting = (app_id, request_id) => {
  return axios.get(`${API_URL}/get-setting`, {
    params: {
      app_id,
      request_id,
    },
  });
};
