import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLead, getSetting } from "../services/apiService";
import LoadingOverlay from "../components/Loading";
import Swal from "sweetalert2";

const CheckData = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [countdown, setCountdown] = useState(180);
  const [loadings, setLoadings] = useState(false);
  const [leadStatus, setLeadStatus] = useState(null);
  const [appData, setAppData] = useState({ app_id: null, request_id: null });
  const [kycSegment, setKycSegment] = useState(null);
  const [offerCard, setOfferCard] = useState(null);
  const [limitOffer, setLimitOffer] = useState(null);
  const [settings, setSettings] = useState(null);
  const id = localStorage.getItem("id");
  const userId = localStorage.getItem("userId");
  const finalId = id !== null ? id : userId;
  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countdown]);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  useEffect(() => {
    if (finalId) {
      const interval = setInterval(() => {
        getLead(finalId)
          .then((response) => {

            const { app_id, request_id, status, kyc_segment, offer_card, limit_offer } = response.data.data;
              setKycSegment(kyc_segment);
              setOfferCard(offer_card);
              setLimitOffer(limit_offer);
            setLeadStatus(status);
            setAppData((prev) => {
              if (prev.app_id !== app_id || prev.request_id !== request_id) {
                return { app_id, request_id };
              }
              return prev;
            });

            if (status === "Cancel" || status === "Reject") {
              navigate(`/vpbank-creditcard/cancelCreditCard${location.search}`);
            } else if (status === "Processing") {
              clearInterval(interval);
              setLoadings(false);
              Swal.fire({
                title: 'Hồ sơ đã được phê duyệt',
                text: 'Hồ sơ của bạn đã được ngân hàng phê duyệt',
                icon: 'info',
                confirmButtonText: 'OK',
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [id,location.search, navigate]);

  useEffect(() => {
    const { app_id, request_id } = appData;
    if (app_id && request_id) {
      getSetting(app_id, request_id)
        .then((response) => {
          setSettings(response.data);
          localStorage.setItem("settings", JSON.stringify(response.data));

        })
        .catch((error) => {
          console.error("Error fetching settings:", error);
        });
    }
  }, [appData]);

  return (
    <div className="flex flex-col items-center p-5 bg-white h-screen">
      {loadings && <LoadingOverlay />}

      <h1 className="uppercase text-lg font-bold mt-6 text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-500 text-center">
        Đang chờ phê duyệt từ ngân hàng
      </h1>
      <div className="w-[95%] h-[210px] bg-[#850101] mt-8 rounded-2xl custom-shadow">
        <div className="flex flex-row m-3">
          <p className="text-sm text-white flex-wrap font-light ml-2 leading-6 w-[70%] mt-9 mr-2">
            Ngân hàng xử lí thông tin của bạn, quy trình xử lý thường diễn ra
            trong 3 phút. Vui lòng không đóng trình duyệt trong khoảng thời
            gian này. Còn
            <span className="text-orange-500 font-bold"> {formatTime(countdown)}</span>
          </p>
          <img
            src={require("../assets/logo/Frame.png")}
            alt="List Icon"
            className="mr-2 w-20 h-28 mt-7"
          />
        </div>
      </div>
      <div className="items-center justify-center mt-9 mb-14">
        {leadStatus === "Processing" && (
          <button
            onClick={() => navigate(`/vpbank-creditcard/chooseProduct${location.search}`)}
            className="bg-gray-300 rounded-lg w-64 h-10 items-center justify-center mb-5 transition-transform duration-300 hover:scale-105 hover:bg-gray-300"
          >
            <span className="text-gray-800 text-base font-semibold">Tiếp tục</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default CheckData;
