import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLinkVkyc } from "../services/apiService";

const GuideVKYC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const id = localStorage.getItem("id");
  const userId = localStorage.getItem("userId");
  const finalId = id !== null ? id : userId;

  const checkLinkVkyc = async () => {
    try {
      const response = await getLinkVkyc(finalId);
      console.log('Check link', response);

      const link = response?.data?.data?.link_vkyc;
      console.log("Link VKYC nhận được:", link);

      if (link) {
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          if (/Mobi|Android/i.test(navigator.userAgent)) {
            window.location.href = link;
          } else {
            window.open(link, "_blank");
          }
          navigate(`/vpbank-creditcard/waiting${location.search}`);
        }, 3000);

        if (intervalId) {
          clearInterval(intervalId);
          setIntervalId(null);
        }
      } else {
        console.log("Chưa có link_vkyc, tiếp tục thử lại...");
      }
    } catch (error) {
      console.error("Có lỗi xảy ra khi gọi API", error);
    }
  };

  const startCheckingVkyc = () => {
    if (!intervalId) {
      const id = setInterval(checkLinkVkyc, 5000);
      setIntervalId(id);
    }
  };

  const handleReadyClick = () => {
    if (isClicked) return;

    setIsClicked(true);
    setLoading(true);

    checkLinkVkyc();
    startCheckingVkyc();
  };

  return (
    <div className="flex flex-col items-center p-4 bg-white">
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-5 rounded-lg shadow-lg">
            <p className="text-center font-bold">Hệ thống đã kết nối!</p>
            <p className="text-center">Bạn sẽ được điều hướng tới trang Video Call...</p>
          </div>
        </div>
      )}

      <div className="w-full text-left mt-2 ml-10">
        <p className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-500">
          Hướng dẫn VKYC
        </p>
        <p className="text-[12px] font-light">Sau đây, bạn sẽ được kết nối Tổng đài Video call.</p>
      </div>
      <div className="top-8 p-3 text-white mt-8 bg-[#850101] transform rounded-[15px] w-full max-w-md custom-shadow">
        <div className="text-xs  font-bold ml-3 mt-2">Quy trình gọi video gồm 3 bước:</div>
        <div className="flex items-center gap-4 px-4 mt-3 ml-2">
          <img id="frontImage" src={require("../assets/guide1.png")} alt="image" className="w-[9%]" />
          <p className="text-xs">Chờ kết nối tổng đài viên</p>
        </div>
        <div className="flex items-center gap-4 px-4 mt-2 ml-2">
          <img id="frontImage" src={require("../assets/guide2.png")} alt="image" className="w-[9%]" />
          <p className="text-xs">Xác thực khuôn mặt & CMND/CCCD</p>
        </div>
        <div className="flex items-center gap-4 px-4 mt-2 ml-2 mb-3">
          <img id="frontImage" src={require("../assets/guide3.png")} alt="image" className="w-[9%]" />
          <p className="text-xs">Đọc & Ký kết hợp đồng điện tử</p>
        </div>
      </div>

      <div className="top-8 p-3 mt-8 bg-gray-200 transform rounded-[15px] w-full max-w-md custom-shadow">
        <div className="text-xs font-bold ml-3 mt-2">Hướng dẫn nhỏ để bạn chuẩn bị sẵn cho cuộc gọi video</div>
      <div className="flex items-center gap-4 px-4 mt-3 ml-2">
          <img
            id="frontImage"
            src={require("../assets/gui1.png")}
            alt="image"
            className="w-[9%]"
          />
          <p className="text-xs">Chuẩn bị CMND/CCCD</p>
        </div>
      <div className="flex items-center gap-4 px-4 mt-3 ml-2">
          <img
            id="frontImage"
            src={require("../assets/gui2.png")}
            alt="image"
            className="w-[7%]"
          />
          <p className="text-xs ml-1">Thời gian gọi 5 - 8 phút</p>
        </div>
        <div className="flex items-center gap-4 px-4 mt-3 ml-2 mb-3">
          <img
            id="frontImage"
            src={require("../assets/gui3.png")}
            alt="image"
            className="w-[7%]"
          />
          <p className="text-xs ml-1">
            Không gian yên tĩnh, có kết nối mạng tốt
          </p>
        </div>
      </div>

      <div className="items-center justify-center mt-10 mb-10">
        <button
          onClick={handleReadyClick}
          className="bg-gradient-to-r from-red-900 to-red-700 rounded-[10px] w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105"
          disabled={loading || isClicked}
        >
          <p className="text-white text-base font-semibold">
            {loading || isClicked ? "Đang kết nối ..." : "Tôi đã sẵn sàng"}
          </p>
        </button>
      </div>
    </div>
  );
};

export default GuideVKYC;
