import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderRegister from "../components/HeaderReigister";
import {
  CheckPhone,
  checkPhoneExists,
  DeletePhone,
  getDataPhone,
  getGender,
  getMaritalStatus,
  Starting_info,
  urlDecode,
} from "../services/apiService";
import { useUserContext } from "../UserContext";
import LoadingOverlay from "../components/Loading";
import "../output.css";
import Swal from "sweetalert2";

const Step1Screen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentStep] = useState(1);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [maritalStatuss, setMaritalStatuss] = useState([]);
  const [genders, setGenders] = useState([]);
  const { userInfo, setUserInfo } = useUserContext();
  const [loadings, setLoadings] = useState(false);
  const [errors, setErrors] = useState({
    fullName: false,
    phoneNumber: false,
    email: false,
    dob: false,
    gender: false,
    maritalStatus: false,
  });
  const [phoneExists, setPhoneExists] = useState(null);
  const [phoneDuplicates, setPhoneDuplicates] = useState(null);
  const validatePhoneNumber = (phone) => {
    const validPrefixes = [
      "032",
      "033",
      "034",
      "035",
      "036",
      "037",
      "038",
      "039",
      "052",
      "055",
      "056",
      "058",
      "059",
      "070",
      "076",
      "077",
      "078",
      "079",
      "081",
      "082",
      "083",
      "084",
      "085",
      "086",
      "087",
      "088",
      "089",
      "09",
    ];
    return validPrefixes.some((prefix) => phone.startsWith(prefix));
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valid = true;
    let newErrors = {};

    if (!userInfo.full_name) {
      newErrors.fullName = "Vui lòng nhập họ tên!";
      valid = false;
    } else {
      const nameValidation = validateName(userInfo.full_name);
      if (!nameValidation.isValid) {
        newErrors.fullName = nameValidation.errorMessage;
        valid = false;
      }
    }

    if (!userInfo.phone_number || !validatePhoneNumber(userInfo.phone_number)) {
      newErrors.phoneNumber = "Số điện thoại không hợp lệ!";
      valid = false;
    }

    if (!userInfo.email || !validateEmail(userInfo.email)) {
      newErrors.email = "Email không hợp lệ!";
      valid = false;
    }

    if (!userInfo.dob) {
      newErrors.dob = "Vui lòng nhập ngày sinh!";
      valid = false;
    } else {
      const dobDate = new Date(userInfo.dob);
      const age = new Date().getFullYear() - dobDate.getFullYear();
      if (age < 18) {
        newErrors.dob = "Bạn phải từ 18 tuổi trở lên!";
        valid = false;
      }
    }

    if (!selectedGender) {
      newErrors.gender = "Vui lòng chọn giới tính!";
      valid = false;
    }

    if (!selectedStatus) {
      newErrors.maritalStatus = "Vui lòng chọn tình trạng hôn nhân!";
      valid = false;
    }

    const checkResult = await CheckPhoneNumber(userInfo.phone_number);
    if (!checkResult.valid) {
      newErrors.phoneNumber = "Số điện thoại đã được sử dụng!";
      valid = false;
    }

    setErrors(newErrors);
    if (!valid) return;

    const dataToSubmit = {
      ...userInfo,
      gender: selectedGender,
      marital_status: selectedStatus,
      partner: new URLSearchParams(location.search).get("partner"),
      click_id: new URLSearchParams(location.search).get("click_id"),
      affiliate_code: new URLSearchParams(location.search).get("affiliate_code"),
    };

    setUserInfo(dataToSubmit);

    try {
      const response = await Starting_info(dataToSubmit);
      const newUserId = response.data.data.id;
      localStorage.setItem("id", newUserId);
      navigate(`/vpbank-creditcard/OTPScreen${location.search}`);
    } catch (error) {
      console.error("Lỗi khi tạo hồ sơ:", error);
    }
  };


  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const info = urlParams.get("info");
    if (info) {
      setLoadings(true);
      urlDecode({ data: info })
        .then((response) => {
          const { name, phone, email } = response.data.decoded;
          setUserInfo({ full_name: name, phone_number: phone, email: email });
        })
        .catch((error) => console.error("Error decoding URL:", error))
        .finally(() => setLoadings(false));
    }
  }, [location]);

  const CheckPhoneNumber = async (phone_number) => {
    let newErrors = {};
    let valid = true;

    try {
      const response = await checkPhoneNumberExists({ phone_number });

      if (
        response?.data?.message ===
          "Phone number already exists in the partner system." &&
        response?.data?.status === false
      ) {
        newErrors.phoneNumber = true;
        valid = false;
        return;
      } else {
        const checkResponse = await CheckPhone({ phone_number });
        if (checkResponse?.data?.status === false) {
          newErrors.phoneNumber = true;
          valid = false;
        }
      }
    } catch (error) {
      console.error("Error validating phone number:", error);
      valid = false;
    }

    return { newErrors, valid };
  };

  const checkPhoneNumberExists = async (phone_number) => {
    setLoadings(true);
    console.log("Số điện thoại gửi đi:", phone_number);

    try {
      const response = await checkPhoneExists(phone_number);
      console.log("check phone bước vpBank:", phone_number);

      if (
        response.data.message ===
          "Phone number already exists in the partner system." &&
        response.data.status === false
      ) {
        setPhoneExists(true);

        Swal.fire({
          title: "Cập nhật hồ sơ",
          text: "Còn vài bước nữa sẽ hoàn tất hồ sơ, bạn có muốn tiếp tục không?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Có",
          cancelButtonText: "Không",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const internalSteps = await getDataPhone(phone_number.phone_number);
            const userId = internalSteps.data.data.id;
            const kyc_step = internalSteps.data.data.kyc_segment;
            const dob = internalSteps.data.data.dob;
            const phoneStep = internalSteps.data.data.phone_number;
            const yearOfBirth = new Date(dob).getFullYear();
            const status = internalSteps.data.data.status;
            const currentStep = internalSteps.data.data.internal_steps;

            localStorage.removeItem("id");
            localStorage.setItem("userId", userId);
            localStorage.setItem("kyc_step", kyc_step);
            localStorage.setItem("yearOfBirth", yearOfBirth);
            localStorage.setItem("phoneStep", phoneStep);

            if (status === "Reject" || status === "Cancel") {
              Swal.fire({
                title: "Hồ sơ không hợp lệ",
                text: "Hồ sơ của bạn đã bị đóng hoặc đã bị từ chối. Vui lòng tạo hồ sơ mới.",
                icon: "error",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.href = "/vpbank-creditcard";
                }
              });
            } else if (!status && currentStep === "JOB_INFO") {
              navigate("/vpbank-creditcard/checkdata");
            } else if (status && status !== "Reject" && status !== "Cancel") {
              switch (currentStep) {
                case "STARTING_INFO":
                  navigate(`/vpbank-creditcard/OTPScreen${location.search}`);
                  break;
                case "BASIC_INFO":
                  navigate("/vpbank-creditcard/step3Screen");
                  break;
                case "RESIDENCE_INFO":
                  navigate("/vpbank-creditcard/step4Screen");
                  break;
                case "JOB_INFO":
                  navigate("/vpbank-creditcard/chooseProduct");
                  break;
                case "EKYC_ID":
                  navigate("/vpbank-creditcard/face");
                  break;
                case "EKYC_LIVENESS":
                  navigate("/vpbank-creditcard/contact");
                  break;
                case "REFERENCE_INFO":
                  if (kyc_step !== "ETB_VERIFIED") {
                    navigate("/vpbank-creditcard/guidevkyc");
                  } else {
                    navigate("/vpbank-creditcard/econtract");
                  }
                  break;
                case "EKYC_FRONT":
                  navigate("/vpbank-creditcard/ocr-back");
                  break;
                case "EKYC_BACK":
                case "EKYC_QR":
                  if (kyc_step !== "ETB_VERIFIED") {
                    navigate("/vpbank-creditcard/contact");
                  } else {
                    navigate("/vpbank-creditcard/face");
                  }
                  break;
                default:
                  break;
              }
            }
          }
        });
      } else if (
        response.data.message === "Phone number does not exist." &&
        response.data.status === "success"
      ) {
        await checkPhone({
          phone_number: userInfo.phone_number,
        });
        console.error("Error checking phone number:");
        setPhoneExists(null);
      }
    } catch (error) {
      await checkPhone({
        phone_number: userInfo.phone_number,
      });
      console.error("Error checking phone number:", error);
      setPhoneExists(null);
    } finally {
      setLoadings(false);
    }
  };
  const checkPhone = async (phone_number) => {
    setLoadings(true);
    try {
      const response = await CheckPhone(phone_number);
      if (response.data.status === false) {
        setPhoneDuplicates(true);

        Swal.fire({
          title: "Cập nhật hồ sơ",
          text: "Còn vài bước nữa sẽ hoàn tất hồ sơ, bạn có muốn tiếp tục?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Có",
          cancelButtonText: "Không",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const internalSteps = await getDataPhone(phone_number.phone_number);
            const userId = internalSteps.data.data.id;
            const kyc_step = internalSteps.data.data.kyc_segment;
            const dob = internalSteps.data.data.dob;
            const phoneStep = internalSteps.data.data.phone_number;
            const yearOfBirth = new Date(dob).getFullYear();
            const status = internalSteps.data.data.status;
            const currentStep = internalSteps.data.data.internal_steps;
            localStorage.removeItem("id");
            localStorage.setItem("userId", userId);
            localStorage.setItem("kyc_step", kyc_step);
            localStorage.setItem("yearOfBirth", yearOfBirth);
            localStorage.setItem("phoneStep", phoneStep);

            if (status === "Reject" || status === "Cancel") {
              Swal.fire({
                title: "Hồ sơ không hợp lệ",
                text: "Hồ sơ của bạn đã bị đóng hoặc đã bị từ chối. Vui lòng tạo hồ sơ mới.",
                icon: "error",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.href = "/vpbank-creditcard";
                }
              });
            } else if (!status && currentStep === "JOB_INFO") {
              navigate("/vpbank-creditcard/checkdata");
            } else if (status && status !== "Reject" && status !== "Cancel") {
            switch (currentStep) {
              case "STARTING_INFO":
                navigate(`/vpbank-creditcard/OTPScreen${location.search}`);
                break;
              case "BASIC_INFO":
                navigate("/vpbank-creditcard/step3Screen");
                break;
              case "RESIDENCE_INFO":
                navigate("/vpbank-creditcard/step4Screen");
                break;

              default:
                break;
            }
          } else {
            await DeletePhone(phone_number);
            window.location.href = "/vpbank-creditcard";
          }}
        });
      } else {
        setPhoneDuplicates(null);
      }
    } catch (error) {
      console.error("Error checking phone number:", error);
      setPhoneDuplicates(null);
    } finally {
      setLoadings(false);
    }
  };
  useEffect(() => {
    setLoadings(true);
    getMaritalStatus()
      .then((response) => {
        setMaritalStatuss(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);
  useEffect(() => {
    setLoadings(true);
    getGender()
      .then((response) => {
        setGenders(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);

  const validateName = (name) => {
    if (!name || typeof name !== "string") {
      return {
        isValid: false,
        errorMessage: "Tên không được để trống.",
      };
    }
    const specialCharRegex = /[!@#$%^&*~&*()_+=[\]{};':"\\|,.<>/?]/;
    if (specialCharRegex.test(name)) {
      return {
        isValid: false,
        errorMessage: "Tên không được chứa ký tự đặc biệt.",
      };
    }
    let cleanedName = name.replace(specialCharRegex, "");

    cleanedName = cleanedName.trim();

    if (name !== cleanedName) {
      return {
        isValid: false,
        errorMessage: "Tên không được có khoảng trắng ở đầu hoặc cuối.",
      };
    }
    if (cleanedName.includes("  ")) {
      return {
        isValid: false,
        errorMessage: "Tên không được có 2 khoảng trắng liên tiếp.",
      };
    }

    if (cleanedName.split(" ").length < 2) {
      return {
        isValid: false,
        errorMessage: "Họ và tên phải có ít nhất 2 từ.",
      };
    }

    return { isValid: true, cleanedName };
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <div className="flex flex-col items-center p-4 bg-white ">
      {loadings && <LoadingOverlay />}

      <HeaderRegister currentStep={currentStep} />

      <div className="mt-8 w-full max-w-md bg-[#850101] rounded-2xl p-4 custom-shadow">
        <div className="text-center mb-3 ml-1">
          <p className="text-lg font-bold text-white uppercase">
            Thông tin cá nhân
          </p>
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-normal ml-2 mb-1">
            Họ và tên:
          </label>
          <input
            className={`h-10 rounded-md px-2 text-sm bg-white w-full ${
              errors.fullName ? "border border-red-500" : ""
            }`}
            placeholder="Nhập họ và tên"
            type="text"
            value={userInfo.full_name}
            onChange={(e) =>
              setUserInfo({
                ...userInfo,
                full_name: e.target.value,
              })
            }
          />
          {errors.fullName && (
            <p className="text-red-500 text-xs">{errors.fullName}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-white text-sm font-normal ml-2 mb-1">
            Số điện thoại:
          </label>
          <input
            className={`h-10 rounded-md text-sm px-2 bg-white w-full ${
              errors.phoneNumber ? "border border-red-500" : ""
            }`}
            placeholder="Nhập số điện thoại"
            type="tel"
            pattern="[0-9]*"
            inputMode="numeric"
            maxLength={10}
            value={userInfo.phone_number}
            onChange={(e) => {
              const input = e.target.value.replace(/[^0-9+]/g, "");
              const isValid = input.length === 10;
              setUserInfo({
                ...userInfo,
                phone_number: input,
              });
              setErrors((prevErrors) => ({
                ...prevErrors,
                phoneNumber: false,
                phoneNumber: !isValid,
              }));
            }}
          />
          {errors.phoneNumber && (
            <p className="text-red-500 text-xs">
              Số điện thoại phải đúng định dạng hoặc đã tồn tại.
            </p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-normal ml-2 mb-1">
            Email:
          </label>
          <input
            className={`h-10 rounded-md px-2 text-sm bg-white w-full ${
              errors.email ? "border border-red-500" : ""
            }`}
            placeholder="Nhập email"
            type="text"
            value={userInfo.email}
            onChange={(e) => {
              setUserInfo({
                ...userInfo,
                email: e.target.value,
              });
              setErrors((prevErrors) => ({
                ...prevErrors,
                email: !validateEmail(e.target.value),
              }));
            }}
          />
          {errors.email && (
            <p className="text-red-500 text-xs">Email không hợp lệ.</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-white text-sm font-normal ml-2 mb-1">
            Ngày, Tháng, Năm sinh:
          </label>
          <input
            className={`h-10 rounded-md text-sm px-2 bg-white w-full ${
              errors.dob || errors.dobUnderage ? "border border-red-500" : ""
            }`}
            placeholder="Nhập ngày tháng năm sinh"
            type="date"
            value={userInfo.dob}
            onChange={(e) => setUserInfo({ ...userInfo, dob: e.target.value })}
          />

          {errors.dob && (
            <p className="text-red-500 text-xs">
              Ngày sinh không được để trống.
            </p>
          )}
          {errors.dobUnderage && (
            <p className="text-red-500 text-xs">
              Bạn phải trên 18 tuổi để đăng ký.
            </p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-white text-sm font-normal ml-2 mb-1">
            Giới tính:
          </label>
          <div className="flex bg-white rounded-lg p-2">
            {genders.map((gender) => (
              <div className="flex items-center mb-2" key={gender.code}>
                <input
                  type="checkbox"
                  id={`gender-${gender.code}`}
                  checked={selectedGender === gender.code}
                  onChange={() =>
                    setSelectedGender(
                      selectedGender === gender.code ? "" : gender.code
                    )
                  }
                  className="hidden"
                />
                <div
                  className="w-5 h-5 border-[0.7px] border-[#850101] rounded-[6px] flex items-center justify-center cursor-pointer"
                  onClick={() =>
                    setSelectedGender(
                      selectedGender === gender.code ? "" : gender.code
                    )
                  }
                >
                  {selectedGender === gender.code && (
                    <span className="bg-[#850101] rounded-[3px] w-[13px] h-[13px]" />
                  )}
                </div>
                <label
                  htmlFor={`gender-${gender.code}`}
                  className="cursor-pointer ml-1 p-2"
                >
                  {gender.name}
                </label>
              </div>
            ))}
          </div>
          {errors.gender && (
            <p className="text-red-500 text-xs">
              Giới tính không được để trống.
            </p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-white text-sm font-normal ml-2 mb-1">
            Tình trạng hôn nhân:
          </label>
          <div className="flex flex-col bg-white rounded-lg p-2">
            {maritalStatuss.map((status) => (
              <div className="flex items-center mb-2" key={status.id}>
                <input
                  type="checkbox"
                  id={`status-${status.id}`}
                  checked={selectedStatus === status.id}
                  onChange={() =>
                    setSelectedStatus(
                      selectedStatus === status.id ? null : status.id
                    )
                  }
                  className="hidden"
                />
                <div
                  className="w-5 h-5 border-[0.7px] border-[#850101] rounded-[6px] flex items-center justify-center cursor-pointer"
                  onClick={() =>
                    setSelectedStatus(
                      selectedStatus === status.id ? null : status.id
                    )
                  }
                >
                  {selectedStatus === status.id && (
                    <span className="bg-[#850101] rounded-[3px] w-[13px] h-[13px]" />
                  )}
                </div>
                <label
                  htmlFor={`status-${status.id}`}
                  className="cursor-pointer ml-2"
                >
                  {status.name}
                </label>
              </div>
            ))}
          </div>
          {errors.maritalStatus && (
            <p className="text-red-500 text-xs">
              Tình trạng hôn nhân không được để trống.
            </p>
          )}
        </div>
      </div>
      <div className="mt-6 mb-2">
        <button
          onClick={handleFormSubmit}
          className="bg-gradient-to-r from-red-900 to-red-700 rounded-[17px] w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105"
        >
          <p className="text-white text-base font-semibold">Tiếp tục</p>
        </button>
      </div>
    </div>
  );
};

export default Step1Screen;
