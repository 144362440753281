import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderRegister from "../components/HeaderReigister";
import Select from "react-select";
import LoadingOverlay from "../components/Loading";
import { isMobile, isTablet } from "react-device-detect";
import {
  fetchCities,
  fetchDistricts,
  fetchWards,
} from "../services/locationService";
import {
  getCompany,
  getCreateToLOS,
  getEmployeeStatus,
  getJobPosition,
  Job_info,
} from "../services/apiService";
import { useUserContext } from "../UserContext";
import Swal from "sweetalert2";
const Step4Screen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentStep] = useState(4);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [ward, setWard] = useState("");
  const [employeeStatuss, setEmployeeStatuss] = useState([]);
  const [companys, setCompanys] = useState([]);
  const [employerStatus, setEmployerStatus] = useState("");
  const [otherEmployerName, setOtherEmployerName] = useState("");
  const [detailedAddress, setDetailedAddress] = useState("");
  const [incomeAmount, setIncomeAmount] = useState("");
  const { userInfo, setUserInfo } = useUserContext();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [jobPositions, setJobPositions] = useState([]);
  const [selectedJobPosition, setSelectedJobPosition] = useState("");
  const [errors, setErrors] = useState({});
  const id = localStorage.getItem("id");
  const userId = localStorage.getItem("userId");
  const finalId = id !== null ? id : userId;
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);
  const [isOtherCompany, setIsOtherCompany] = useState(false);
  const [filteredJobPositions, setFilteredJobPositions] =
    useState(jobPositions);
  const [loadings, setLoadings] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState({
    user_agent: "",
    country: "",
    city: "",
    type_of_device: "",
    operating_system: "",
    browser_name: "",
  });

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const typeOfDevice = isMobile ? "Mobile" : isTablet ? "Tablet" : "Desktop";
    const operatingSystem = /Windows/i.test(userAgent)
      ? "Windows 10"
      : /Mac/i.test(userAgent)
      ? "macOS"
      : /Linux/i.test(userAgent)
      ? "Linux"
      : "Other OS";
    const browserName = /Chrome/i.test(userAgent)
      ? "Chrome"
      : /Firefox/i.test(userAgent)
      ? "Firefox"
      : /Safari/i.test(userAgent)
      ? "Safari"
      : "Other Browser";

    setDeviceInfo((prev) => ({
      ...prev,
      user_agent: userAgent,
      type_of_device: typeOfDevice,
      operating_system: operatingSystem,
      browser_name: browserName,
    }));
  }, []);

  const handleLocationSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    fetch(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
    )
      .then((response) => response.json())
      .then((data) => {
        setDeviceInfo((prev) => ({
          ...prev,
          country: data.countryName || "Unknown",
          city: data.locality || data.principalSubdivision || "Unknown",
        }));
      })
      .catch((error) => console.error("Error fetching location data:", error));
  };

  const handleLocationError = () => {
    console.warn("User denied geolocation request or it failed.");
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      handleLocationSuccess,
      handleLocationError
    );
  }, []);

  const isEmployerStatusEnabled =
    employerStatus &&
    !["EMPL3", "EMPL5"].includes(
      employeeStatuss.find((status) => status.id === employerStatus.value)?.code
    );
  useEffect(() => {
    if (!isEmployerStatusEnabled) {
      setSelectedJobPosition("");
      setSelectedCompany("");
      setOtherEmployerName("");
      setProvince("");
      setDistrict("");
      setWard("");
      setDetailedAddress("");
    }
  }, [isEmployerStatusEnabled]);
  const handleFinalSubmit = async () => {
    setLoadings(true);
    try {
      const newErrors = {};
  
      if (!employerStatus)
        newErrors.employerStatus = "Vui lòng chọn tình trạng công việc";
      if (isEmployerStatusEnabled) {
        if (!selectedJobPosition)
          newErrors.selectedJobPosition = "Vui lòng chọn vị trí công việc";
        if (!selectedCompany) newErrors.selectedCompany = "Vui lòng chọn công ty";
        if (isOtherCompany && !otherEmployerName)
          newErrors.otherEmployerName = "Vui lòng nhập tên công ty khác";
        if (!province) newErrors.province = "Vui lòng chọn tỉnh/thành phố";
        if (!district) newErrors.district = "Vui lòng chọn quận/huyện";
        if (!ward) newErrors.ward = "Vui lòng chọn xã/phường";
        if (!detailedAddress)
          newErrors.detailedAddress = "Vui lòng nhập địa chỉ chi tiết";
      }
  
      if (!incomeAmount)
        newErrors.incomeAmount = "Vui lòng nhập thu nhập hàng tháng";
  
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }
  
      const dataToSubmit = {
        ...userInfo,
        employer_name: selectedCompany.value,
        employer_status: employerStatus.value,
        job_position: selectedJobPosition.value,
        other_employer_name: otherEmployerName,
        employer_province: province,
        employer_district: district,
        employer_ward: ward,
        employer_address: detailedAddress,
        income_amount: incomeAmount,
        device: deviceInfo,
      };
  
      const dataStatus = {
        employer_name: selectedCompany.value,
        employer_status: employerStatus.value,
        job_position: selectedJobPosition.value,
        other_employer_name: otherEmployerName,
        employer_province: province,
        employer_district: district,
        employer_ward: ward,
        employer_address: detailedAddress,
        income_amount: incomeAmount,
        device: deviceInfo,
      };
  
      setUserInfo(dataToSubmit);
  
      const jobInfoResponse = await Job_info(finalId, dataStatus);
      const losResponse = await getCreateToLOS(jobInfoResponse.data.data.id);
      Swal.fire({
        title: "Đăng ký hồ sơ thành công!",
        text: "Chờ phê duyệt từ ngân hàng!",
        icon: "success",
      });
  
      navigate(`/vpbank-creditcard/checkData${location.search}`);
    } catch (error) {
      console.error("Error posting data:", error);
  
      const errorMessage =
        JSON.stringify(error.response?.data, null, 2) || "Đã xảy ra lỗi";
  
      Swal.fire({
        icon: "error",
        title: "Lỗi từ hệ thống",
        text: errorMessage?.response?.message ?? "",
        confirmButtonText: "Đồng ý",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        background: "#fff",
        timer: 5000,
      });
    } finally {
      setLoadings(false);
    }
  };
  

  useEffect(() => {
    setLoadings(true);
    fetchCities()
      .then((response) => {
        setProvinces(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);
  useEffect(() => {
    setLoadings(true);
    getCompany()
      .then((response) => {
        setCompanys(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);
  useEffect(() => {
    if (companys.length > 0) {
      setIsLoadingCompany(false);
    }
  }, [companys]);

  useEffect(() => {
    setLoadings(true);
    getJobPosition()
      .then((response) => {
        setJobPositions(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);
  useEffect(() => {
    setLoadings(true);

    getEmployeeStatus()
      .then((response) => {
        setEmployeeStatuss(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);

  const handleProvinceChange = (e) => {
    const selectedProvince = e.target.value;
    setProvince(selectedProvince);
    setLoadings(true);

    const provinceObject = provinces.find(
      (prov) => prov.code === selectedProvince
    );

    if (provinceObject) {
      fetchDistricts(selectedProvince)
        .then((response) => {
          setDistricts(response.data);
        })
        .catch((error) => console.error(error))
        .finally(() => setLoadings(false));
    }

    setWards([]);
  };

  const handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    setDistrict(selectedDistrict);
    setLoadings(true);

    fetchWards(selectedDistrict)
      .then((response) => {
        setWards(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  };
  const handleCompanyChange = (selected) => {
    setLoadings(true);
    setSelectedCompany(selected);
    setIsOtherCompany(selected?.label === "Others");
    setTimeout(() => {
      setLoadings(false);
    }, 1000);
    if (selected) {
      setOtherEmployerName("");
    }
  };
  const provincesArray = provinces.map((city) => (
    <option key={city.id} value={city.code}>
      {city.name}
    </option>
  ));

  const districtsArray = Object.entries(districts);
  const wardsArray = Object.entries(wards);

  const formatCurrency = (value) => {
    if (!value) return "";
    const numberValue = Number(value.replace(/[^0-9]/g, ""));
    return numberValue.toLocaleString("vi-VN", {
      style: "currency",
      currency: "VND",
    });
  };
  const jobPositionNames = {
    POSH3: "Quản lý",
    POSH29: "Nhân Viên/Chuyên viên văn phòng",
    POSH33: "Công nhân/Bảo vệ",
    POSH24: "Tài xế/lái xe",
    POSH28: "Nhân viên kinh doanh (xe ô tô, bảo hiểm, bất động sản, chính khoán,...)",
    POSH42: "Khác",
    POSH11: "Chủ hộ kinh doanh",
    POSH12: "Thực tập/Sinh viên",
    POSH10: "Nghĩ hưu/Thất nghiệp/Nội trợ",
  };
  
  const handleEmployerStatusChange = (selectedStatus) => {
    setEmployerStatus(selectedStatus);
    const selectedStatusObj = employeeStatuss.find(
      (status) => status.id === selectedStatus.value
    );

    if (selectedStatusObj) {
      const { code } = selectedStatusObj;
      let filteredPositions = [];

      switch (code) {
        case "EMPL1":
          filteredPositions = jobPositions.filter((position) =>
            [
              "POSH3",
              "POSH29",
              "POSH33",
              "POSH24",
              "POSH28",
              "POSH42",
            ].includes(position.code)
          );
          break;
        case "EMPL2":
          filteredPositions = jobPositions.filter((position) =>
            ["POSH29", "POSH33", "POSH24", "POSH42"].includes(position.code)
          );
          break;
        case "EMPL3":
          filteredPositions = jobPositions.filter((position) =>
            ["POSH10", "POSH12"].includes(position.code)
          );
          break;
        case "EMPL4":
          filteredPositions = jobPositions.filter((position) =>
            ["POSH11"].includes(position.code)
          );
          break;
        case "EMPL5":
          filteredPositions = jobPositions.filter((position) =>
            ["POSH10"].includes(position.code)
          );
          break;
        default:
          filteredPositions = jobPositions;
          break;
      }

      if (
        filteredPositions.length === 0 ||
        !filteredPositions.some(
          (position) => position.code === selectedJobPosition
        )
      ) {
        setSelectedJobPosition("");
      }

      const positionsWithNames = filteredPositions.map((position) => ({
        ...position,
        name: jobPositionNames[position.code] || position.code,
      }));
  
      setFilteredJobPositions(positionsWithNames);
    }
  };

  return (
    <div className="flex flex-col items-center p-4 bg-white">
      {(loadings || isLoadingCompany) && <LoadingOverlay />}

      <HeaderRegister currentStep={currentStep} />
      <div className="mt-8 w-[100%] bg-[#850101] rounded-2xl shadow-lg p-4 custom-shadow">
        <h2 className="text-lg font-bold text-white uppercase text-center">Công việc</h2>

        <div className="mt-5">
          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Tình trạng công việc:
            </label>
            <Select
              value={employerStatus}
              onChange={handleEmployerStatusChange}
              options={employeeStatuss.map((status) => ({
                label: status.name,
                value: status.id,
              }))}
              placeholder="Chọn tình trạng công việc"
            />
            {errors.employerStatus && (
              <p className="text-red-500 text-xs mt-1">
                {errors.employerStatus}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Vị trí công việc:
            </label>
            <Select
              options={filteredJobPositions.map((position) => ({
                value: position.code,
                label: position.name,
              }))}
              value={selectedJobPosition}
              onChange={(selected) => setSelectedJobPosition(selected)}
              isClearable
              placeholder="Chọn vị trí công việc"
            />

            {errors.selectedJobPosition && (
              <p className="text-red-500 text-xs mt-1">
                {errors.selectedJobPosition}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Tên công ty:
            </label>
            <Select
              name="company"
              value={selectedCompany}
              onChange={handleCompanyChange}
              onMenuOpen={() => {
                if (companys.length === 0) setIsLoadingCompany(true);
              }}
              onMenuClose={() => setIsLoadingCompany(false)}
              options={
                isLoadingCompany
                  ? [{ label: "Đang tải dữ liệu...", value: null }]
                  : companys.map((company) => ({
                      label: company.name,
                      value: company.code,
                    }))
              }
              isLoading={isLoadingCompany}
              isDisabled={!isEmployerStatusEnabled}
              placeholder="Chọn công ty"
            />
            {errors.selectedCompany && (
              <p className="text-red-500 text-xs mt-1">
                {errors.selectedCompany}
              </p>
            )}
          </div>
          {isOtherCompany && (
            <div className="mb-4">
              <label className="block text-white text-sm font-normal ml-2 mb-1">
                Tên công ty khác:
              </label>
              <input
                className="h-10 rounded-md text-sm px-2 bg-white w-full"
                placeholder="Nhập tên công ty khác"
                type="text"
                value={otherEmployerName}
                onChange={(e) => setOtherEmployerName(e.target.value)}
              />
              {errors.otherEmployerName && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.otherEmployerName}
                </p>
              )}
            </div>
          )}
          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Tỉnh/Thành phố:
            </label>
            <select
              value={province}
              onChange={handleProvinceChange}
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              disabled={!isEmployerStatusEnabled}
            >
              <option value="" disabled>
                Chọn tỉnh/thành phố
              </option>
              {provincesArray}
            </select>

            {errors.province && (
              <p className="text-red-500 text-xs mt-1">{errors.province}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Quận/Huyện:
            </label>
            <select
              value={district}
              onChange={handleDistrictChange}
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              disabled={!isEmployerStatusEnabled}
            >
              <option value="" disabled>
                Chọn quận/huyện
              </option>
              {districtsArray.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </select>

            {errors.district && (
              <p className="text-red-500 text-xs mt-1">{errors.district}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Xã/Phường:
            </label>
            <select
              value={ward}
              onChange={(e) => setWard(e.target.value)}
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              disabled={!isEmployerStatusEnabled}
            >
              <option value="" disabled>
                Chọn xã/phường
              </option>
              {wardsArray.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </select>
            {errors.ward && (
              <p className="text-red-500 text-xs mt-1">{errors.ward}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Địa chỉ chi tiết:
            </label>
            <input
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              placeholder="Nhập chi tiết địa chỉ"
              type="text"
              value={detailedAddress}
              onChange={(e) => setDetailedAddress(e.target.value)}
              maxLength={70}
              disabled={!isEmployerStatusEnabled}
            />
            {errors.detailedAddress && (
              <p className="text-red-500 text-xs mt-1">
                {errors.detailedAddress}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Thu nhập hàng tháng:
            </label>
            <input
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              placeholder="Thu nhập hàng tháng"
              type="text"
              min="0"
              step="0.01"
              value={formatCurrency(incomeAmount)}
              onChange={(e) => {
                const rawValue = e.target.value.replace(/[^0-9]/g, "");
                setIncomeAmount(rawValue);
              }}
            />
            {errors.incomeAmount && (
              <p className="text-red-500 text-xs mt-1">{errors.incomeAmount}</p>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-6 mb-8">
        <button
          onClick={handleFinalSubmit}
          className="bg-gradient-to-r from-red-900 to-red-700 rounded-[17px] w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105"        >
        
          <span className="text-white text-base font-semibold">Tiếp tục</span>
        </button>
      </div>
    </div>
  );
};

export default Step4Screen;
